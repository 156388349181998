import React, {Component} from 'react';
import './Person.css'
import config from '../../config.json';
import connect from 'react-redux/es/connect/connect';
import {bindActionCreators} from 'redux';
import {actionCreators as routerActions} from '../../store/RouterActions';
import {actionCreators as plannedItemActions} from '../../store/PlannedItemStore';
import {actionCreators as personActions} from '../../store/PersonStore';
import PropTypes from 'prop-types';

import checkInInvertedImage from '../../images/check-ind-venstre.png';
import checkOutInvertedImage from '../../images/check-ud-venstre.png';
import planInvertedImage from '../../images/planlaeg-venstre.png';
import checkInImage from '../../images/check-ind-hojre.png';
import checkOutImage from '../../images/check-ud-hojre.png';
import radialPlanImage from '../../images/planlaeg-hojre.png';
import illImage from '../../images/sygdom-hojre.png';
import illInvertedImage from '../../images/sygdom-venstre.png';

import flagImage from '../../images/flag.png';
import starImage from '../../images/notification.png';
import moment from 'moment';

class Person extends Component {

    render(){
        const props = this.props;
        const getToday = () => {
            return moment();
        };
        const person = props.person;
        if(!person) return null;

        const unansweredEvent = props.state.eventState.events.filter(x => x.invitations.find(y => y.status === 0 && y.personId === person.id) != null)

        const date = getToday();
        const itemsToday = props.state.plannedItemState.plans
            .filter(x =>
                x.personId === person.id &&
                date.isSameOrAfter(x.startTime, 'day') &&
                date.isSameOrBefore(x.endTime)
            );
        const item = itemsToday.find(x => date.isAfter(x.startTime) && date.isBefore(x.endTime));
        const colorCodes = props.state.plannedItemState.colorCodes;
        const checkColor = colorCodes && colorCodes.find(x => x.systemName === 'checked');
        const uncheckColor = colorCodes && colorCodes.find(x => x.systemName === 'unchecked');

        const RadialMenu = () => {
            if(!person.showRadial || this.element === undefined) return null;

            const checkIn = () => {
                props.personActions.checkIn(person.id);
            };
            const checkOut = () => {
                props.personActions.checkOut(person.id);
            };
            const plan = () => {
                props.routerActions.navigate(`/plan/${person.id}`)
                props.plannedItemActions.showPlanForm(true);
            };
            const planSick = () => {
                const start = moment().set('hour', 7).set('minutes', 0);;
                const end = moment().set('hour', 15).set('minutes', 0);

                props.plannedItemActions.createPlannedItem(
                    person.id, 111,
                    start.format('YYYY-MM-DD HH:mm'), end.format('YYYY-MM-DD HH:mm')
                );
            };

            const pct = this.element.getBoundingClientRect().left / document.documentElement.clientWidth;
            const inverse = pct > 0.5;

            return (
                <div className={`radial${inverse ? ' inversed' : ''}`}>
                    <div className="checkin">
                        <img src={inverse ? checkInInvertedImage : checkInImage} alt="checkin"/>
                        <button onClick={checkIn}>
                            {checkColor && checkColor.name}
                        </button>
                    </div>
                    <div className="checkout">
                        <img src={inverse ? checkOutInvertedImage : checkOutImage} alt="checkout"/>
                        <button onClick={checkOut}>
                            {uncheckColor && uncheckColor.name}
                        </button>
                    </div>

                    <div className="plan">
                        <img src={inverse ? planInvertedImage : radialPlanImage} alt="plan"/>
                        <button onClick={plan}>
                            Planlæg
                        </button>
                    </div>

                    <div className="ill">
                        <img src={inverse ? illInvertedImage : illImage}  alt='illness'/>
                        <button onClick={planSick}>
                            Syg i dag
                        </button>
                    </div>
                </div>
            );
        };

        const toggleRadialMenu = () => {
            props.personActions.showRadial(person.id, !!(!person.showRadial));
        };

        let className = 'person clearfix';
        let style;

        if(person.status === 'checked'){
            className += ' circle checked';
            style = { borderColor:`${checkColor && checkColor.hexColor}`, borderRadius: '50%', borderStyle: 'solid'};
        }
        if(person.status === 'unchecked'){
            className += ' circle unchecked';
            style = { borderColor:`${uncheckColor && uncheckColor.hexColor}`, borderRadius: '50%', borderStyle: 'solid'};
        }
        className = person.showRadial ? className + ' circle radial' : className;

        if(item){
            className += ' circle';
            style = { borderColor:`${item.colorCode.hexColor}`, borderRadius: '50%', borderStyle: 'solid'};
        }

        const isLoading = false;
        const profileImageClass = isLoading ? 'profile-img loading' : 'profile-img';

        const radial = RadialMenu();
        const birthDate = person.birthDate ? moment(person.birthDate) : null;
        const isB = birthDate && birthDate.isSame(moment().year(birthDate.year()), 'd');
        const celebrateComp = isB ? <img className="birthday" src={flagImage} alt=''/> : '';

        const defaultImagePath = "/uploads/12/images/default person - datadisplay.jpg";

        return (
            <div className={className} onClick={() => toggleRadialMenu()} ref= { c => this.element = c}>
                <div className="profile clearfix" >
                    <img className={profileImageClass} src={config.server + (person.imagePath || defaultImagePath) } alt="" style={style ? style : {}}/>
                    {unansweredEvent.length > 0 && <div className='profile-img-star'><img src={starImage} alt={'event_invite'}/><span className='event-count'>{unansweredEvent.length}</span></div> }
                    {celebrateComp}
                    {radial}
                </div>
                <div className="flytext">{person.name}</div>
                <div className="flytext-title">{person.title}</div>
                <div className="flytext-phoneNumber">{person.phoneNumber}</div>
                <div className="flytext-plans">
                    {itemsToday &&
                        itemsToday.map((x,i) => {
                                if(item && x.id === item.id) return null;
                                return(
                                    <div key={i} className='plan'>
                                        {moment(x.startTime).format('HH:mm')}
                                        <span style={{backgroundColor: x.colorCode.hexColor}} className='dot'/>
                                    </div>
                                )
                            }
                        )
                    }
                </div>
            </div>
        );
    }
}

Person.propTypes = {
    person: PropTypes.object
};

export default connect(
    state => ({state: state}),
    dispatch => ({
        routerActions: bindActionCreators(routerActions, dispatch),
        personActions: bindActionCreators(personActions, dispatch),
        plannedItemActions: bindActionCreators(plannedItemActions, dispatch)
    })
)(Person);


