import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import * as Team from './TeamStore';
import * as PlannedItem from './PlannedItemStore';
import * as Person from './PersonStore';
import * as Allerodnyt from './AllerodnytStore';
import * as Slide from './SlideStore';
import * as Tv2News from './Tv2NewsStore';
import * as DrNews from './DrNewsStore';
import * as Calendar from './CalendarStore';
import * as GridStore from './GridStore';
import * as DocumentStore from './DocumentStore';
import * as AuthStore from './AuthStore';
import * as ResourceStore from './ResourceStore';
import * as EventStore from './EventStore';

export default function configureStore(history, initialState) {
    const reducers = {
        [Team.reducerName]: Team.reducer,
        plannedItemState: PlannedItem.reducer,
        [Person.reducerName]: Person.reducer,
        alleroednytState: Allerodnyt.reducer,
        slideState: Slide.reducer,
        tv2newsState: Tv2News.reducer,
        [Calendar.reducerName]: Calendar.reducer,
        [GridStore.reducerName]: GridStore.reducer,
        [DocumentStore.reducerName]: DocumentStore.reducer,
        [AuthStore.reducerName]: AuthStore.reducer,
        [ResourceStore.reducerName]: ResourceStore.reducer,
        [EventStore.reducerName]: EventStore.reducer,
        [DrNews.reducerName]: DrNews.reducer
    };

    const middleware = [
        thunk,
        routerMiddleware(history)
    ];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
    }

    const appReducer = combineReducers({
        ...reducers
    });

    const rootReducer = (state, action) => {
        return appReducer(state, action);
    };

    return createStore(
        connectRouter(history)(rootReducer),
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}
