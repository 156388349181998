import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {bindActionCreators} from 'redux';
import * as ResourceStore from '../../store/ResourceStore';
import * as TeamStore from '../../store/TeamStore';
import * as PersonStore from '../../store/PersonStore';
import Unit from './Unit';
import BookingPerson from "./BookingPerson";

const TeamChooser = props => {
    const showMenu = props.resState.showTeamMenu;
    if(!showMenu) return null;

    const units = props.resState.units;
    const teams = props.teamState.teams;
    const personsMap = props.personState.persons;
    const bookingPerson = personsMap[props.resState.createBooking.personId];

    const unitBooking = units.find(x => x.id === props.resState.createBooking.unitId);
    const createBooking = props.resState.createBooking;

    const renderPersonChooser = (team) => {
        return(
            <div className='dialog-overlay' onClick={() => props.resourceActions.setBookingField('teamId', null)}>
                <div className='dialog' onClick={(e) => e.stopPropagation()}>
                    <h1>Vælg en person</h1>
                    <div className='persons'>
                        <BookingPerson person={team.teamLeader} onClick={(pId) => props.resourceActions.setBookingField('personId', pId)}/>
                        {team.members.map(x =>
                            <BookingPerson key={x.id} person={x} onClick={(pId) => props.resourceActions.setBookingField('personId', pId)}  />
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const renderTeam = (team) => {
        return(
            <div key={team.id} className={`team${createBooking.teamId === team.id ? '' : ' active'}`} onClick={
                () => {
                    props.resourceActions.setBookingField('teamId', team.id);
                    props.resourceActions.setBookingField('personId', null);
                }}
            >
                <div className='text'>
                    <div>{team.name}</div>
                    <div className='text-muted text-sm'>{team.description}</div>
                </div>
            </div>
        )
    };

    const renderRow = (mainTeam, teams) => {
        if(mainTeam === null){
            return(
                <div className='team-row'>
                    {teams && teams.map(x => renderTeam(x))}
                </div>
            )
        }
        return(
            <div className='team-row'>
                <h2>{mainTeam.name}</h2>
                {renderTeam(mainTeam)}
                {teams && teams.map(x => renderTeam(x))}
            </div>
        )
    };

    if(props.resState.createBooking.teamId &&  !props.resState.createBooking.personId){
        return renderPersonChooser(teams.find(x => x.id === props.resState.createBooking.teamId));
    }

    return(
        <div className='dialog-overlay' onClick={() => props.resourceActions.showTeamMenu(false)}>
            <div className='dialog teamchooser' onClick={(e) => e.stopPropagation()}>
                <div className='unit-container'>
                    <Unit unit={unitBooking} hideText noMenu hideName/>
                    <div className='clearfix'/>
                </div>
                <h1>{bookingPerson ? bookingPerson.name : 'Hvem'} reserverer "{unitBooking.name}"?</h1>
                {renderRow(null, teams)}
                {/*
                {renderRow(administration, [pladsen])}
                {renderRow(vejomraadet, [gronvej])}
                {renderRow(komunaleEjendome, [team2, team3, tvear])}
                {renderRow(vearkstedOgGenbrug, [genbrug])}
                */}
                <div className={`dialog-btn${createBooking.teamId ? '' : ' disabled'}`} onClick={
                    () => {props.resourceActions.showTimeMenu(false);
                    props.resourceActions.showTeamMenu(true);
                    props.resourceActions.setTeamAndBook(createBooking.teamId, createBooking.personId);
                    }}
                >Done</div>
            </div>
        </div>
    )
};

export default connect(
    state => ({
        teamState: state[TeamStore.reducerName],
        resState: state[ResourceStore.reducerName],
        personState: state[PersonStore.reducerName]
    }),
    dispatch => ({
        resourceActions: bindActionCreators(ResourceStore.actionCreators, dispatch),
    })
)(TeamChooser);