import React from 'react';
import Team from './Team'
import './TeamList.css';
import ColorLegend from '../planning/ColorLegend';
import connect from 'react-redux/es/connect/connect';
import * as ResourceStore from '../../store/ResourceStore';
import moment from 'moment';

const CustomTeamList = props => {
    const units = props.state[ResourceStore.reducerName].units;
    const bookings = props.state[ResourceStore.reducerName].bookings;
    const now = moment();
    const currentBookings = bookings.filter(x => now.isSameOrAfter(x.startTime) && now.isSameOrBefore(x.endTime));

    const teams = props.state.teamState.teams;
    if(teams.length < 1) return null;

    /*
    const addUnits = team => {
        team.units = currentBookings.reduce((result,b) => {
            if(b.teamId === team.id){
                result.push(units.find(u => u.id === b.unitId));
            }
            return result;
        }, []);
    };
    */

    const installation = teams.find(x => x.id === 68);
    const murerAfdelingen = teams.find(x => x.id === 69);
    const sikringOgAdgang = teams.find(x => x.id === 70);
    const administration = teams.find(x => x.id === 71);
    const malerService = teams.find(x => x.id === 73);
    const blikon = teams.find(x => x.id == 117);



    // Vi har umiddelbart ikke brug for at tilføje units her - men hvis vi får brug for det, skal det gøres på hvert team
    //addUnits(administration);
    //addUnits(pladsen);

    return (
        <div className="team-list">
            {installation &&
                <Team
                    team={installation}
                    width={25}
                    height={98}
                />
            }
             {murerAfdelingen &&
                <Team
                    team={murerAfdelingen}
                    width={15}
                    height={98}
                />
            }
            {sikringOgAdgang &&
                <Team
                    team={sikringOgAdgang}
                    width={15}
                    height={98}
                />
            }
            {malerService &&
                <Team
                    team={malerService}
                    width={15}
                    height={98}
                />
            }
            {blikon &&
                <Team
                    team={blikon}
                    width={15}
                    height={98}
                />
            }
            {administration &&
                <Team
                    team={administration}
                    width={15}
                    height={98}
                />
            }
            <ColorLegend />
        </div>
    );
};

export default connect(
    state => ({state: state})
)(CustomTeamList);